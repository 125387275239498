import Swiper from "swiper";
import { Thumbs, Navigation } from 'swiper/modules';

let galleryThumbs = Array.prototype.slice.call(document.querySelectorAll('.ys-gallery-thumbs'));
if(galleryThumbs) {
  galleryThumbs.forEach((item, i, all) => {
    var swiper = new Swiper(item.querySelector('.gallery-thumbs-bottom'), {
      modules: [Thumbs, Navigation],
      loop: true,
      spaceBetween: 12,
      slidesPerView: 3,
      freeMode: true,
      watchSlidesProgress: true,
      navigation: {
        nextEl: item.querySelector('.swiper-button-next'),
        prevEl: item.querySelector('.swiper-button-prev'),
      },
      breakpoints: {
        768: {
          slidesPerView: 4,
          spaceBetween: 40,
        },
        1024: {
          slidesPerView: 6,
        },
      },
    });

    var swiper2 = new Swiper(item.querySelector('.gallery-thumbs-top'), {
      modules: [Thumbs, Navigation],
      loop: true,
      spaceBetween: 0,
      slidesPerView: 1,
      navigation: {
        nextEl: item.querySelector('.swiper-button-next'),
        prevEl: item.querySelector('.swiper-button-prev'),
      },
      thumbs: {
        swiper: swiper,
      },
    });
  });
}