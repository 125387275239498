import WaveSurfer from 'wavesurfer.js'

let ysAudioPlayer = Array.prototype.slice.call(document.querySelectorAll('.ys-audio-player'));
  ysAudioPlayer.forEach((item, i, all) => {
    const playButton = item.querySelector(".ys-audio-player-play-button")
    const playButtonIcon = item.querySelector(".ys-audio-player-play-button-icon")
    const pauseButtonIcon = item.querySelector(".ys-audio-player-pause-button-icon")
    const waveform = item.querySelector(".ys-audio-player-waveform")
    const volumeIcon = item.querySelector(".ys-audio-player-volume-icon")
    const volumeSlider = item.querySelector(".ys-audio-player-volume-slider")
    const currentTime = item.querySelector(".ys-audio-player-current-time")
    const totalDuration = item.querySelector(".ys-audio-player-total-duration")
    // --------------------------------------------------------- //
    /**
     * Initialize Wavesurfer
     * @returns a new Wavesurfer instance
     */
    const initializeWavesurfer = () => {
      return WaveSurfer.create({
        container: waveform,
        responsive: true,
        barWidth: 2,
        barGap: 4,
        barRadius: 100,
        height: 16,
        waveColor: "#000000",
        progressColor: "#181B2F",
      })
    }
    // --------------------------------------------------------- //
    // Functions
    /**
     * Toggle play button
     */
    const togglePlay = () => {
      wavesurfer.playPause()
      const isPlaying = wavesurfer.isPlaying()
      if (isPlaying) {
        playButtonIcon.classList.add('hidden');
        playButtonIcon.classList.remove('block');
        pauseButtonIcon.classList.remove('hidden')
      } else {
        playButtonIcon.classList.remove('hidden');
        playButtonIcon.classList.add('block');
        pauseButtonIcon.classList.add('hidden');
      }
    }
    /**
     * Handles changing the volume slider input
     * @param {event} e
     */
    const handleVolumeChange = e => {
      // Set volume as input value divided by 100
      // NB: Wavesurfer only excepts volume value between 0 - 1
      const volume = e.target.value / 100
      wavesurfer.setVolume(volume)
      // Save the value to local storage so it persists between page reloads
      localStorage.setItem("audio-player-volume", volume)
    }
    /**
     * Retrieves the volume value from local storage and sets the volume slider
     */
    const setVolumeFromLocalStorage = () => {
      // Retrieves the volume from local storage, or falls back to default value of 50
      const volume = localStorage.getItem("audio-player-volume") * 100 || 50
      volumeSlider.value = volume
    }
    /**
     * Formats time as HH:MM:SS
     * @param {number} seconds
     * @returns time as HH:MM:SS
     */
    const formatTimecode = seconds => {
      // return new Date(seconds * 1000).toISOString().substr(13, 8)
      return new Date(seconds * 1000).toISOString().substr(14, 5)
    }
    /**
     * Toggles mute/unmute of the Wavesurfer volume
     * Also changes the volume icon and disables the volume slider
     */
    const toggleMute = () => {
      const isMuted = wavesurfer.getMuted()
      if (isMuted) {
        //volumeIcon.src = "assets/icons/mute.svg"
        volumeSlider.disabled = true
        wavesurfer.setMuted(true)
      } else {
        volumeSlider.disabled = false
        wavesurfer.setMuted(false)
        //volumeIcon.src = "assets/icons/volume.svg"
      }
    }
    // --------------------------------------------------------- //
    // Create a new instance and load the wavesurfer
    const wavesurfer = initializeWavesurfer()
    wavesurfer.load(item.dataset.audio)
    // --------------------------------------------------------- //
    // Javascript Event listeners
    window.addEventListener("load", setVolumeFromLocalStorage)
    playButton.addEventListener("click", togglePlay)
    volumeIcon.addEventListener("click", toggleMute)
    volumeSlider.addEventListener("input", handleVolumeChange)
    // --------------------------------------------------------- //
    // Wavesurfer event listeners
    wavesurfer.on("ready", () => {
      // Set wavesurfer volume
      wavesurfer.setVolume(volumeSlider.value / 100)
      // Set audio track total duration
      const duration = wavesurfer.getDuration()
      totalDuration.innerHTML = formatTimecode(duration)
    })
    // Sets the timecode current timestamp as audio plays
    wavesurfer.on("audioprocess", () => {
      const time = wavesurfer.getCurrentTime()
      currentTime.innerHTML = formatTimecode(time)
    })
    // Resets the play button icon after audio ends
    wavesurfer.on("finish", () => {
      playButtonIcon.classList.remove('hidden');
      pauseButtonIcon.classList.add('hidden');
    })
  });