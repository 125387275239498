let toggleBtn = document.getElementById('toggle-pass');
let toggleBtnConf = document.getElementById('toggle-pass-confirm');

if (toggleBtn) {
  toggleBtn.addEventListener("click", toggePassword);
}

if (toggleBtnConf) {
    toggleBtnConf.addEventListener("click", togglePasswordConfirm);
}

function toggePassword() {
  let inputPass = document.getElementById('password_login');

  if (inputPass.type == "password") {
    inputPass.type = "text";
  } else {
    inputPass.type = "password";
  }
}

function togglePasswordConfirm() {
    let inputPass = document.getElementById('password_login_confirm');

    if (inputPass.type == "password") {
        inputPass.type = "text";
    } else {
        inputPass.type = "password";
    }
}
