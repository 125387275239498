import $ from 'jquery';
import Alpine from 'alpinejs';
import persist from '@alpinejs/persist';
import focus from '@alpinejs/focus';
import Swiper from "swiper";
Alpine.plugin(persist);
Alpine.plugin(focus);
import * as bootstrap from 'bootstrap';
window.bootstrap = bootstrap;
require('./custom/toggle-password');
require('./custom/gallery-thums');
require('./custom/audio-player');
require('./select2.min');
require('./flowbite/flowbite');
import 'swiper/css';
import { Navigation } from "swiper/modules";
import Echo from 'laravel-echo';
import WaveSurfer from 'wavesurfer.js'
import RecordRTC, { invokeSaveAsDialog } from 'recordrtc';
import 'video.js/dist/video-js.css';
import videojs from "video.js";
window.Pusher = require('pusher-js');

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: process.env.MIX_PUSHER_APP_KEY,
    cluster: process.env.MIX_PUSHER_APP_CLUSTER,
    forceTLS: true
});
window.jQuery = window.$ = $;
window.WaveSurfer = WaveSurfer;
window.Alpine = Alpine
window.Swiper = Swiper;
window.RecordRTC = RecordRTC;
window.videojs = videojs;
window.Navigation = Navigation;
Alpine.start()

Alpine.store('fetchStore', {
    async fetchBlobMedia(id, userId) {
        try {
            const response = await fetch(`${process.env.MIX_MEMORY_API_URL}/media/${id}/content`, {
                method: 'GET',
                headers: {
                    'accept': 'application/json',
                    'X-YT-UserId': userId,
                    'Authorization': `Bearer ${process.env.MIX_MEMORY_API_KEY}`,
                }
            });
            const blob = await response.blob();
            return URL.createObjectURL(blob);
        } catch (error) {
            console.error('Failed to fetch image:', error);
            return null;
        }
    }
});